import React from 'react';

import SEO from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import Image from 'components/common/Image';
import Link from 'components/common/Link';
import Panel from 'components/common/Panel';

import BlogCarousel from 'components/cards/BlogCarousel';

import careerPathways from 'constants/components/carousel/career-pathways-carousel';
import panelData from 'constants/careers/panel';
import { isLpgDemergerOn } from 'constants/global';

import customerServicePathway from 'images/careers/pathways-customer-service.jpg';
import commercialPathway from 'images/careers/careers-commercial.jpg';
import deliveryPathway from 'images/careers/careers-logistics.jpg';
import processingPathway from 'images/careers/careers-production.jpg';
import apprenticesPathway from 'images/careers/careers-apprentices.jpg';
import studentsPathway from 'images/careers/careers-graduates.jpg';

function CareersPage() {

	let filteredPathways = careerPathways;

	if (isLpgDemergerOn) {
		filteredPathways = careerPathways.filter(object => object.id !== 'delivery');
	}

	const pathways = filteredPathways.map(object => {
		return {...object, 
			mediaSrc: object.id === 'customer' ? customerServicePathway : 
					  object.id === 'commercial' ? commercialPathway :
					  object.id === 'delivery' ? deliveryPathway :
					  object.id === 'processing' ? processingPathway :
					  object.id === 'apprentices' ? apprenticesPathway :
					  object.id === 'students' ? studentsPathway : ''}
	});

	return (
		<>
            <SEO title="Careers | Kleenheat | Opportunities to work with us"
				 titleBespoke="true"
            	 description="Learn about our job opportunities, career pathways and the benefits you'll enjoy as a Kleenheat and Wesfarmers team member."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-py-lg"
			                 theme="mer-theme--cyan">
			        <div className="container">
			            <div className="row mer-panel justify-content-center">
		                    <div className="col-10 col-lg-6 mer-text--size-lg order-2 order-lg-1">

								<div className="content">
		                            <h2>Careers at Kleenheat</h2>
		                            <p>Thrive with people who enjoy working together in the spirit of collaboration. We offer a diverse range of interesting roles that put you – and your work-life balance – at the heart of things.</p>
									<p>Our workplace culture is based on our values of safety, collaboration, customer service, accountability and integrity. We treat each other with respect and live our values every day. This lets us embrace each person’s unique skills, talents, backgrounds and ideas.</p>
									<p>Whatever your career and personal goals, we help you reach them.</p>
		                        </div>

								<div className="mer-panel__actions mer-button-group">
									<Link text="Current opportunities"
										link="https://kleenheat.bigredsky.com/"
										linkTarget="_blank"
										linkClass="mer-button mer-button--primary" />
								</div>
							</div>
							
							<div className="col-10 col-lg-5 offset-lg-1 mer-panel__videos order-1 order-lg-2">
							    <div className="mer-panel__video">
									<iframe src="https://player.vimeo.com/video/757833378?title=0&amp;byline=0&amp;portrait=0&amp;badge=0" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-vimeo-tracked="true" data-ready="true"></iframe>
							    </div>
							</div>
			            </div>
					</div>
			    </LayoutPanel>

				{/* wesfarmers  */}
				<LayoutPanel background="mer-bg--gs-grey-lighter"
							padding="mer-py-lg"
							id="wesfarmers">
					<div className="container">

						<div className="row mer-panel mer-panel--layout-reverse align-items-center justify-content-center mer-text--size-lg">

							<div className="content col-10 col-sm-7 offset-sm-1">
								<h2 className="mer-typography--headline3">Be part of the Wesfarmers group.</h2>
								<p>When one door opens… so does another! When you’re a Kleenheat employee, you have opportunities to grow and progress across the broader Wesfarmers businesses. Welcome the chance to become an expert in your area and go places with your career.</p>
							</div>

							<div className="mer-panel__images col-5 col-sm-3 pr-sm-0">
								<Image className="mer-panel__image"
									src="/icons/external/logo-wesfarmers.svg"
									alt="Wesfarmers"
							/>
							</div>
						</div>
					</div>
				</LayoutPanel>

		    	{/* usp */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-pt-ju mer-pb-lg"
			                 id="team">
			        <div className="container">
			        	<div className="row align-items-center justify-content-center">
	                		<div className="col-10 col-sm-8 mer-text--size-lg">
				                <div className="text-sm-center content">
				                	<h1 className="mer-color--primary-cyan mer-typography--headline3">When you’re on Team Kleenheat,<br />you’re on top of great benefits.</h1>
									<p>Join Kleenheat and we’ll provide a range of benefits to keep life flowing – inside and outside your workplace.</p>
								</div>
								<ul className="pl-4 pt-4 pl-sm-4 ml-sm-3 pl-lg-5 ml-lg-4">
									<li className="mer-typography--body2">Allocation of Wesfarmers shares*</li>
									<li className="mer-typography--body2 mt-0">Wesfarmers discount card (Bunnings, Kmart, Target, Officeworks)*</li>
									<li className="mer-typography--body2 mt-0">Participation in our annual incentive plan*</li>
									<li className="mer-typography--body2 mt-0">Paid parental leave*</li>
									<li className="mer-typography--body2 mt-0">Dedicated resources and support to help employees achieve a healthy balance between work and family responsibilities</li>
									<li className="mer-typography--body2 mt-0">Education assistance</li>
									<li className="mer-typography--body2 mt-0">Access to an employee assistance program</li>
									<li className="mer-typography--body2 mt-0">Annual flu vaccinations</li>
									<li className="mer-typography--body2 mt-0">Salary sacrifice opportunities</li>
									<li className="mer-typography--body2 mt-0">Kleenheat discounts</li>
									<li className="mer-typography--body2 mt-0">Discounted tickets to Wesfarmers Arts sponsored events</li>
									<li className="mer-typography--body2 mt-0">A range of employee discounts including private health insurance and vehicles</li>
									<li className="mer-typography--body2 mt-0">Novated lease vehicles</li>
									<li className="mer-typography--body2 mt-0">Service awards</li>
									<li className="mer-typography--body2 mt-0">Community events involvement</li>
									<li className="mer-typography--body2 mt-0">Social Club membership</li>
								</ul>
								<p className="pl-lg-4"><small>*Subject to eligibility criteria</small></p>
				            </div>
	                	</div>

			        	<Panel data={panelData} 
			                   id="panel-1"
						/>
						<Panel data={panelData} 
			                   id="panel-2"
						/>
						<Panel data={panelData} 
			                   id="panel-3"
						/>
			        </div>
		        </LayoutPanel>

		    	{/* operate  */}
				<LayoutPanel background="mer-bg--gs-grey-lighter"
							border="mer-border--bottom"
							padding="mer-py-de"
							id="operate">
					<div className="container">

						<div className="row mer-panel align-items-center justify-content-center mer-text--size-lg">

							<div className="col-10 col-sm-7 col-lg-6 offset-sm-1 order-sm-2">
								<h2 className="content mer-typography--headline3">Where we operate</h2>
								{isLpgDemergerOn ? (
									<div>
										<p>Our head office is in Murdoch, and our production team is based in Kwinana, Western Australia. </p>
										<p>By the way: we’ve been servicing the WA community for over 65 years – that’s a lot of energy!</p>
									</div>
								) : (
									<div>
										<p>Our head office is in Murdoch, and our operations and processing teams are based in Kwinana, Western Australia.</p>
										<p>We also have nine regional branches, plus a network of dealers and agents who look after LPG customers in rural and remote WA, and in the Northern Territory.</p>
										<p>By the way: we’ve been servicing the WA community for over 65 years <span className="mer-text--no-wrap">– that’s a lot of energy!</span></p>
									</div>
								)}
								
							</div>

							<div className="mer-panel__images col-5 col-sm-3 mt-0 order-sm-1">
								{isLpgDemergerOn ? (
									<Image className="mer-panel__image"
										src="/icons/flowmoji/flowmoji-wa.svg"
										alt="Flowmoji WA" />
								) : (
									<Image className="mer-panel__image"
										src="/icons/flowmoji/flowmoji-wa-nt.svg"
										alt="Flowmoji WA NT" />
								)}
								
							</div>
						</div>
					</div>
				</LayoutPanel>

				{/* career pathways carousel */}
				<BlogCarousel blogData={pathways}
							  title="Career pathways"
							  titleHeadingClass="mer-color--primary-cyan mer-typography--headline1"
							  titleCol="mer-text--size-lg col-10 col-lg-6"
							  description={<React.Fragment><p>Our Kleenheat team is bursting with talent. It’s made up of a diverse range of people, from students, graduates and apprentices through to highly skilled and specialised professionals.</p><p className="mb-0">Discover more about them, and the paths you could take.</p></React.Fragment>}
							  merBtnLinkClass="mer-width-100"
							  ctaClass="col-10 d-sm-flex justify-content-sm-center"
							  ctaText="Current opportunities"
							  ctaLink="https://kleenheat.bigredsky.com/"
							  ctaLinkClass="mer-button mer-button--primary"
							  ctaHeadingClass="mer-typography--headline6 mer-button-group"
							  ctaLinkTarget="_blank"
							  panelBg="mer-bg--gs-white"
							  panelSpacing="mer-pt-lg mer-pb-de"
							  panelBorder="mer-border--bottom" />
		    </LayoutMain>
			<Footer />
		</>
	)
}

export default CareersPage;